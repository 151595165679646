const floatlabels = document.querySelectorAll(".js-floatlabel");

if (floatlabels) {
  Array.from(floatlabels).forEach(wrapper => {
    const input = wrapper.querySelector("input, select, textarea");

    if (!input) {
      console.warn(
        `Failed to create a floatlabel. Element doesn't have one of either an <input>, <select>, or <textarea>`,
        wrapper
      );
    } else {
      function updateHasValue() {
        if (input.value && input.value.length > 0) {
          wrapper.classList.remove("no-value");
        } else {
          wrapper.classList.add("no-value");
        }
      }

      input.addEventListener("input", updateHasValue);
      updateHasValue();
    }
  });
}
