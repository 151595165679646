if (document.body.classList.contains('ui-kit')) {
  /*
   * Text Samples
   */
  const textSampleInput = document.getElementById('text-sample');
  const textSamples = document.querySelectorAll('.ui-text-spec > p[contenteditable]');

  if (textSampleInput && textSamples) {
    textSamples.forEach((sample) => {
      sample.uiKitOriginalText = sample.innerText;
    });

    textSampleInput.addEventListener('input', (e) => {
      textSamples.forEach((sample) => {
        sample.innerText = e.target.value;
      });
    });

    textSampleInput.addEventListener('blur', (e) => {
      if (e.target.value === '') {
        textSamples.forEach((sample) => {
          sample.innerText = sample.uiKitOriginalText;
        });
      }
    });
  }


  /*
   * Color Chooser
   */
  const colors = document.querySelectorAll('.ui-color-spec figure');
  const colorModal = document.getElementById('color-modal');
  if (colors && colorModal) {
    const setTextColorButton = document.getElementById('set-color-text');
    const setBgColorButton = document.getElementById('set-color-background');

    colors.forEach((color) => {
      color.addEventListener('click', (e) => {
        e.stopPropagation();
        colorModal.classList.add('visible');
        colorModal.setAttribute('data-color', getComputedStyle(color).backgroundColor);
      });
    });

    setTextColorButton.addEventListener('click', () => {
      textSamples.forEach((sample) => {
        sample.style.color = colorModal.dataset.color;
      });
      colorModal.classList.remove('visible');
    });

    setBgColorButton.addEventListener('click', () => {
      document.body.style.backgroundColor = colorModal.dataset.color;
      colorModal.classList.remove('visible');
    });
  }

  document.addEventListener('click', (e) => {
    if (colorModal.contains(e.target)) {
      e.preventDefault();
    } else if (colorModal.classList.contains('visible')) {
      colorModal.classList.remove('visible');
    }
  });


  /*
   * Escape HTML
   */
   const escapeElements = document.querySelectorAll('.js-escape-html');
   escapeElements.forEach((elem) => {
     elem.innerHTML = elem.innerHTML.replace(/&/g,'&amp;').replace(/</g,'&lt;').replace(/>/g,'&gt;');
   });


   /*
    * Expanding sections
    */
  const sections = document.querySelectorAll('.ui-section');
  sections.forEach((section) => {
    const title = section.querySelector('h2');
    section.classList.add('hidden');
    title.addEventListener('click', () => {
      section.classList.toggle('hidden');
      if (window.glideCarousels) {
        window.glideCarousels.forEach((carousel) => {
          carousel.update();
        });
      }
    });
  });
}
