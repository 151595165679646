const nav = document.querySelector(".js-site-nav");

if (nav) {
  window.addEventListener("scroll", updateSiteNav);
  window.addEventListener("wheel", updateSiteNav);
  window.addEventListener("mouseover", updateSiteNav);

  function updateSiteNav() {
    if (window.scrollY > 0 && nav.classList.contains("transparent")) {
      nav.classList.remove("transparent");
    } else if (window.scrollY <= 0 && !nav.classList.contains("transparent")) {
      nav.classList.add("transparent");
    }
  }

  updateSiteNav();

  const buttonContainer = nav.querySelector(".site-nav__links");
  const buttons = buttonContainer.querySelectorAll("button");
  Array.from(buttons).map((btn) => {
    btn.addEventListener('click', () => {
      window.location = `/${btn.dataset.route}`;
    });
  });
}
