const popupButtons = document.querySelectorAll("[aria-haspopup]");

class PopupButton {
  constructor(button) {
    const controlledId = button.getAttribute("aria-controls");
    this.button = button;
    this.controlledElement = document.getElementById(controlledId);
    this.expanded = false;
    this.popupToggle = this.button.dataset.popupToggle;

    this.button.addEventListener("mouseover", e => {
      e.stopPropagation();
      this.togglePopup();
    });

    this.controlledElement.addEventListener("mouseover", e => {
      e.stopPropagation();
    });
  }

  togglePopup() {
    const expanded = this.button.getAttribute("aria-expanded") === "true";
    this.setPopupState(!expanded);
    // If we're expanded this popup, we may want to hide others
    if (!expanded) {
      this.otherButtons.forEach(otherButton => {
        if (!otherButton.controlledElement.contains(this.button)) {
          otherButton.setPopupState(false);
        }
      });
    }
  }

  setPopupState(expanded) {
    // Update our expanded state first
    this.expanded = expanded;
    // Now update the button's aria-expanded
    this.toggleButton(this.expanded);
    // And all other buttons that also control this popup
    this.relatedButtons.forEach(relatedButton => {
      relatedButton.toggleButton(this.expanded);
    });
    // Update aria-hidden state and add a class
    this.controlledElement.setAttribute("aria-hidden", !this.expanded);
    this.controlledElement.classList[expanded ? "add" : "remove"]("visible");
    // For elements that need to expand height dynamically, set with data-popup-toggle="height" on the button
    if (this.popupToggle === "height") {
      this.controlledElement.style.height = expanded ? `${this.controlledElement.scrollHeight}px` : 0;
    }
  }

  toggleButton(expanded) {
    this.button.setAttribute("aria-expanded", expanded);
  }

  setButtonStore(store) {
    this.buttonStore = store;
  }

  get relatedButtons() {
    return this.buttonStore.buttons.filter(
      button => button.controlledElement === this.controlledElement && button.button !== this.button
    );
  }

  get otherButtons() {
    return this.buttonStore.buttons.filter(button => button.controlledElement !== this.controlledElement);
  }
}

class PopupButtonStore {
  constructor() {
    this.buttons = [];
    document.addEventListener("mouseover", () => {
      this.buttons.forEach(button => {
        button.setPopupState(false);
      });
    });
  }

  create(button) {
    const popupButton = new PopupButton(button);
    popupButton.setButtonStore(this);
    this.buttons.push(popupButton);
  }
}

const buttonStore = new PopupButtonStore();

popupButtons.forEach(button => {
  buttonStore.create(button);
});
