import 'core-js/stable';
import 'regenerator-runtime/runtime';
// import * as dynamicImportPolyfill from 'dynamic-import-polyfill';
import 'loading-attribute-polyfill';

import './vendor/nodelist.foreach';

import "./autoTextArea";
import "./floatLabel";
import "./hasPopup";
import "./rangeScrubber";
import "./siteNav";
import "./ui-kit";

// This needs to be done before any dynamic imports are used.
// dynamicImportPolyfill.initialize();

// Client asked to hide carousel for the time being. Saving code for future use

// const carouselElems = document.querySelectorAll(".js-carousel");
// if (carouselElems.length > 0) {
//   import("./carousel").then(({ default: Carousel }) => {
//     carouselElems.forEach(elem => new Carousel(elem));
//   });
// }

const glideCarousels = document.querySelectorAll(".glide");
if (glideCarousels.length > 0) {
  import("@glidejs/glide").then(Glide => {
    const carousels = [];
    glideCarousels.forEach(carousel => {
      const glideCarousel = new Glide(carousel, { type: "carousel" });
      glideCarousel.mount();
      carousels.push(glideCarousel);
    });
    window.glideCarousels = carousels;
  });
}

const querySelects = document.querySelectorAll(".js-query-select");
if (querySelects.length > 0) {
  import("./querySelect").then(({ default: querySelect }) => {
    querySelects.forEach(elem => querySelect(elem));
  });
}

const clearableSelects = document.querySelectorAll(".js-clearable-select");
if (clearableSelects.length > 0) {
  import("./clearableSelect").then(({ default: clearableSelect }) => {
    clearableSelects.forEach(elem => clearableSelect(elem));
  });
}

const previousSiblingSelectors = document.querySelectorAll(".js-previous-sibling");
if (previousSiblingSelectors.length > 0) {
  import("./previousSibling").then(({ default: setPreviousSibling }) => {
    previousSiblingSelectors.forEach(elem => setPreviousSibling(elem));
  });
}

const tabs = document.querySelectorAll(".js-tabs");
if (tabs.length > 0) {
  import("./tabs").then(({ default: enableTabs }) => {
    enableTabs(tabs);
  });
}
