(() => {
  const autoTextareas = document.querySelectorAll('.js-auto-textarea');
  autoTextareas.forEach((textarea) => {
    textarea.style.resize = 'none';

    textarea.addEventListener('input', () => {
      textarea.style.height = `${textarea.scrollHeight}px`;
    });
  });
})();
