const rangeScrubbers = document.querySelectorAll('.js-range-scrub');

rangeScrubbers.forEach((scrubber) => {
  const controlled = document.getElementById(scrubber.getAttribute('aria-controls'));
  scrubber.addEventListener('input', (e) => {
    const scrollRange = controlled.scrollWidth - controlled.offsetWidth;
    controlled.scrollTo(scrollRange * parseFloat(e.target.value), 0);
  });

  controlled.addEventListener('scroll', (e) => {
    const scrollRange = controlled.scrollWidth - controlled.offsetWidth;
    const currentPercentage = controlled.scrollLeft / scrollRange;
    scrubber.value = currentPercentage;
  });

  window.addEventListener('resize', () => {
    const scrollRange = controlled.scrollWidth - controlled.offsetWidth;
    const currentPercentage = controlled.scrollLeft / scrollRange;
    scrubber.value = currentPercentage;
  });
});
